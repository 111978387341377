import React, {useState} from 'react';
import './Tour.scss';
import {Button} from "@contentmunch/muncher-ui";

export const TourSection: React.FC = () => {

    const [showTour, setShowTour] = useState(false);
    const toggleShowTour = () => {
        setShowTour(!showTour);
    };


    return (

        <section className="section-tour">
            <div className="container">
                <div className="tour-content">
                    <div>
                        <h1>take a virtual tour</h1>
                        <h5>of our rooftop gardens</h5>
                    </div>
                    <Button variant="secondary" onClick={toggleShowTour}>
                        {showTour ? "Close Tour »" : "Click Here »"}
                    </Button>
                </div>
            </div>
            {
                showTour ?
                    <iframe title="virtual tour" width="100%" height="400" src="https://viewer.divein.studio/story/ind-XRC" style={{border: 0}}
                            allowFullScreen></iframe>
                    : <></>
            }


        </section>
    );
};

